<template>
    <label><input type="checkbox" name="" value="" id="" v-model="checked">{{ organization_name }}<span>({{ getDocumentCountByCode }}件)</span></label>
</template>
        
<script>


export default {
    name: 'MenuItemByOrganization',
    props: {
        organization_name: String,
        code: String,
    },
    computed: {
        checked: {
            get: function(){
                return this.$store.getters.getOrganizationCheckedByCode(this.code);
            },
            set: function(checked_bool){
                this.$store.commit("updateOrganizationChecked", {code: this.$props.code, checked: checked_bool});
            },
        },
        getDocumentCountByCode: function(){
            return this.$store.getters.getDocumentCountByCode(this.code);
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>