<template>
    <p><label><input type="checkbox" name="" value="" id="" v-model="checked">{{ affiliation }}<span>({{ getDocumentCountByAffiliationCode }}件)</span></label></p>
    <div ref="toggleOrganizationsAccordion" class="area_more" :class="{active: active}" @click="toggleAccordion"></div>
	<ul class="active" style="display: none">
		<li v-for="organization in getOrganizationsByAffiliationCode()" :key="organization.code">
            <MenuItemByOrganization :organization_name="organization.name" :code="organization.code" />
        </li>
	</ul>
</template>
        
<script>
import MenuItemByOrganization from './MenuItemByOrganization.vue';


export default {
    name: 'MenuItemByAffiliation',
    /**
     * @props {String} affiliation - 都道府県名を受け取る。都道府県名を表示するために使用。
     * @props {String} affiliation_code - 都道府県コードを受け取る。都道府県コードを表示するために使用。
     * @props {Boorean} initial_mounted - 初期表示時にアコーディオンを開くかどうかに使用。初期表示時である場合はtrueを渡す。
     */
    props: {
        affiliation: String,
        affiliation_code: String,
        initial_mounted: Boolean,
    },
    data(){
        return {
            active: false,
            // initial_mounted: true,
        }
    },
    methods: {
        getOrganizationsByAffiliationCode: function () {
            return this.$store.getters.getOrganizationsByAffiliationCode(this.affiliation_code);
        },
        toggleAccordion: function(){
            window.$(this.$refs.toggleOrganizationsAccordion).toggleClass('active');
            window.$(this.$refs.toggleOrganizationsAccordion).next('ul').slideToggle(200);
            this.active = !this.active;
        },
    },
    computed: {
        checked: {
            get: function(){
                return this.$store.getters.getAffiliationCheckedByCode(this.affiliation_code);
            },
            set: function(checked_bool){
                this.$store.commit("updateAffiliationChecked", {affiliation_code: this.$props.affiliation_code, checked: checked_bool});
            },
        },
        getDocumentCountByAffiliationCode: function(){
            return this.$store.getters.getDocumentCountByAffiliationCode(this.affiliation_code);
        },
    },
    components: {
        MenuItemByOrganization,
    },
    mounted: function(){
        if(this.initial_mounted&&this.$props.affiliation=="福岡県"){
            this.toggleAccordion();
            this.$props.initial_mounted = false;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>