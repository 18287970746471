<template>
    <p><label><input type="checkbox" name="" value="" id="" v-model="checked">{{ area }}<span>({{ getDocumentCountByArea }}件)</span></label></p>
    <div ref="toggleAffiliationsAccordion" class="area_more" :class="{active: active}" @click="toggleAccordion"></div>
    <ul class="active" style="display: none">
        <li v-for="affiliation in getAffiliationsByAffiliationCodes()" :key="affiliation.affiliation_code">
            <MenuItemByAffiliation :affiliation="affiliation.affiliation" :affiliation_code="affiliation.affiliation_code" :initial_mounted="true" />
        </li>
    </ul>
</template>
        
<script>
import MenuItemByAffiliation from './MenuItemByAffiliation.vue';


export default {
    name: 'MenuItemByArea',
    props: {
        area: String,
        affiliation_codes: Array,
    },
    data(){
        return {
            active: false,
        }
    },
    methods: {
        getAffiliationsByAffiliationCodes: function () {
            return this.$store.getters.getAffiliationsByAffiliationCodes(this.affiliation_codes);
        },
        toggleAccordion: function(){
            window.$(this.$refs.toggleAffiliationsAccordion).toggleClass('active');
            window.$(this.$refs.toggleAffiliationsAccordion).next('ul').slideToggle(200);
            this.active = !this.active;
        },
    },
    computed: {
        checked: {
            get: function(){
                return this.$store.getters.getAreaCheckedByName(this.area);
            },
            set: function(checked_bool){
                this.$store.commit("updateAreaChecked", {area_name: this.$props.area, checked: checked_bool});
            },
        },
        getDocumentCountByArea: function(){
            return this.$store.getters.getDocumentCountByArea(this.area);
        },
    },
    components: {
        MenuItemByAffiliation,
    },
    mounted: function(){
        if(this.$props.area=="九州・沖縄地方"){
            this.toggleAccordion();
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.accordionFolded {
    display: none;
}
</style>