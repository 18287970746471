<template>
  <header ref="Header">
	<div class="loading-overlay" v-if="searchLoading">
		<div class="sk-chase">
			<div class="sk-chase-dot"></div>
			<div class="sk-chase-dot"></div>
			<div class="sk-chase-dot"></div>
			<div class="sk-chase-dot"></div>
			<div class="sk-chase-dot"></div>
			<div class="sk-chase-dot"></div>
		</div>
	</div>	
		<div id="header">
			<div class="container flex_bw_c">
				<div class="l_box">
					<h1><a href="/"><img :src="require('@/assets/img/logo.svg')" alt="g-finder"></a></h1>
				</div>
				<div class="r_box">
					<div class="flex_bw">
						<ul class="tub_menu flex_st">
							<li id="tub_menu_01" class="active"><a href="#">自治体</a></li>
							<!--li id="tub_menu_02"><a href="#">省庁</a></li>
							<li id="tub_menu_03"><a href="#">お気に入り</a></li-->
						</ul>
						<ul class="sub_menu flex_st">
							<!-- <li><a href="#" target="_blank">利用方法</a></li>
							<li><a href="#" target="_blank">お知らせ<span>2</span></a></li>-->
							<li><a href="https://share.hsforms.com/1EsrARM9rRm2Ht1wmumrn-wqnjcj" target="_blank">お問い合わせ</a></li> 
							<!--1次フェーズ時のソース-->
							<!--<li><a href="#">ログアウト</a></li>-->
							<li class="nav-item" v-if="isAuthenticated && !isLoading"><a href="#" class="nav-link" @click.prevent="logout">ログアウト</a></li>
							<!--/1次フェーズ時のソース-->
							<!--2次フェーズ時のソース-->
							<!--li id="user">
								<button class="user_switch"></button>
								<div id="user_menu">
									<ul>
										<li>
											<a href="">
												<p>アカウント管理</p>
												<p class="f_small">メールアドレス、パスワードの変更</p>
											</a>
										</li>
										<li>
											<a href="">
												<p>ログアウト</p>
											</a>
										</li>
									</ul>
								</div>
							</li-->
							<!--/2次フェーズ時のソース-->
						</ul>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
//import eventBus from '@/components/emitter.js'
import { setUserId } from "firebase/analytics"
import { analytics } from "@/analytics"

export default {
  name: 'NavBar',
  props: ['isAuthenticated', 'isLoading'],
//   props: {
// 	isLoading: Boolean,
// 	isAuthenticated: Boolean,
// 	},
  data() {
    return {
		selectedSize: 'medium',
		headerElement: null,
    };
  },
  methods: {
    logout() {
      this.$auth0.logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      });
    },
    handleButtonClick(size) { // フォントサイズ変更ボタン（小中大）クリック時の処理。もう使用しないため、将来的に削除する。
		this.selectedSize = size;
		this.$emit('sizeButtonClick', size);
    },
	updateHeaderHeight(){
		this.$store.commit("updateHeaderHeight", this.$refs.Header.offsetHeight);
	},
  },
  computed: {
	searchLoading(){
	console.log("getters.searchLoading check");
	console.log(this.$store.getters.searchLoading);
      return this.$store.getters.searchLoading;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateHeaderHeight);
	this.updateHeaderHeight();
  },
  beforeUnmount: function () {
    window.removeEventListener('resize', this.updateHeaderHeight)
  },
  beforeUpdate() {
	console.log("setUserId");
		if(this.isAuthenticated){
			console.log("setUserId", this.$auth0.user.value.sub);
			setUserId(analytics, this.$auth0.user.value.sub);
		} else {
			console.log("setUserId", "anonymous");
		}
		return this.isAuthenticated;
  },
}

</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 要素をコンテンツより前面に（要調整） */
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}
</style>