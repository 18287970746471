/**
 * 
 */
import areas from "@/assets/data/areas.json"
import affiliations from "@/assets/data/affiliations.json"
import organizations from "@/assets/data/organizations.json"

function generate_affiliations(affiliations, organizations){
    for (let i = 0; i < affiliations.length; ++i) {
        for (const area of areas){
            if (area.affiliations.includes(affiliations[i].affiliation)){
                affiliations[i].area = area.area;
            }
        }
        for (const organization of organizations){
            if (affiliations[i].affiliation === organization.affiliation){
                affiliations[i].organizations.push(organization.code);
            }
        }
    }
    return affiliations;
}

/**
 * Arrayの各Obejct（自治体データ Or oraganization）に対し、"checked: false"プロパティを付与して、返す
 * @param {Array} organizations - 自治体基礎データ
 * @returns {Array} organizations - "checked: false"プロパティを持つArray
 */
function generate_organizations(organizations) {
    for (let i = 0; i < organizations.length; ++i) {
        organizations[i].checked = false;
    }
    return organizations;
}

export const filtering_area_organization = {
    state: () => ({
        is_ever_updated: false,
        areas: areas,
        affiliations: generate_affiliations(affiliations, organizations),
        organizations: generate_organizations(organizations),
    }),
    mutations: {
        /**
         * ユーザがエリアのチェックボックスの状態を変更した際に、処理を行う。
         * チェックを付けた場合(checked=true)、配下の全ての都道府県・自治体にチェックを付与するよう変更する。
         * チェックを外した場合(checked=false)、配下の全ての都道府県・自治体にチェックを外すよう変更する。
         * @param {*} state 
         * @param {string} area_name - エリア（"東北", "関東甲信", etc）を受け取る
         * @param {boolean} checked - チェックボックスにチェックが付いたか(true)、外れたか(false)をBool型で受け取る
         */
        updateAreaChecked (state, { area_name, checked }){
            const index = state.areas.findIndex(area => area.area === area_name);
            if (index >= 0){
                let area = state.areas[index];
                area.checked = checked;
                state.areas.splice(index,1,area);
                state.areas[index].affiliations.forEach(affiliation_name => {
                    state.affiliations.forEach((affiliation, index_of_affiliations) => {
                        if(affiliation.affiliation === affiliation_name){
                            affiliation.checked = checked;
                            state.affiliations.splice(index_of_affiliations, 1, affiliation);
                            state.affiliations[index_of_affiliations].organizations.forEach(organization_code => {
                                state.organizations.forEach((organization, index_of_organizations) => {
                                    if(organization.code === organization_code){
                                        organization.checked = checked;
                                        state.organizations.splice(index_of_organizations, 1, organization)
                                    }
                                });
                            });
                        }
                    })
                });
                state.is_ever_updated = true;
            }
        },
        /**
         * ユーザが都道府県のチェックボックスの状態を変更した際に、処理を行う。
         * チェックを付けた場合、
         *  - 配下の全ての自治体にチェックを付与するよう変更する。
         *  - もし同じエリア配下の全ての都道府県にチェックが付与する状態になったら、直属親のエリアにチェックを付与した状態に変更する。
         * チェックを外した場合、
         *  - 配下の全ての自治体にチェックを外すよう変更する。
         *  - 直属の親のエリアにチェックを外すよう変更する。
         * @param {*} state 
         * @param {string} affiliation_code 
         * @param {boolean} checked - 
         */
        updateAffiliationChecked (state, {affiliation_code, checked}){
            const index = state.affiliations.findIndex(affiliation => affiliation.affiliation_code === affiliation_code);
            if (index >= 0){
                const affiliation = state.affiliations[index]
                affiliation.checked = checked;
                state.affiliations.splice(index, 1, affiliation);
                state.affiliations[index].organizations.forEach(organization_code => {
                    state.organizations.forEach((organization, index_of_organizations) => {
                        if (organization.code === organization_code){
                            organization.checked = checked;
                            state.organizations.splice(index_of_organizations, 1 ,organization);
                        }
                    });
                });
                if (checked===true){
                    const index_of_area = state.areas.findIndex(area => area.area === affiliation.area);
                    let any_affiliation_checked_false = false;
                    for (const affiliation of state.affiliations){
                        if((areas[index_of_area].affiliations.includes(affiliation.affiliation))&&(affiliation.checked===false)){
                            any_affiliation_checked_false = true;
                            break
                        }
                    }
                    if(any_affiliation_checked_false===false){
                        let area = state.areas[index_of_area];
                        area.checked = checked;
                        state.areas.splice(index_of_area, 1, area);
                    }
                } else {
                    const index = state.areas.findIndex(area => area.affiliations.includes(affiliation.affiliation))
                    const area = state.areas[index];
                    area.checked = checked;
                    state.areas.splice(index, 1, area);
                }
                state.is_ever_updated = true;
            }
        },
        /**
         * ユーザが自治体のチェックボックスの状態を変更した際に、処理を行う。
         * チェックを付けた場合、
         *  - その自治体にチェックを付与するよう変更する。
         *  - もし同じ都道府県配下の全ての自治体にチェックが付与する状態になったら、直属の親都道府県にチェックを付与した状態に変更する。
         * チェックを外した場合、
         *  - その自治体のチェックを外す(checked = falseに更新する)
         *  - 直属の親の都道府県にチェックが付いている場合、チェックを外す
         *  - 直属の祖先のエリアにチェックが付いている場合、チェックを外す
         * @param {Object} state 
         * @param {string} code 
         * @param {boolean} checked 
         */
        updateOrganizationChecked (state, {code, checked}){
            const index = state.organizations.findIndex(organization => organization.code === code);
            if (index >= 0){
                let organization = state.organizations[index]
                organization.checked = checked;
                state.organizations.splice(index, 1, organization);
                if (checked===true){
                    const affiliation_code = organization.affiliation_code;
                    let any_organization_checked_false = false;
                    for (const organization of state.organizations){
                        if((organization.affiliation_code===affiliation_code)&&(organization.checked===false)){
                            any_organization_checked_false = true;
                            break;
                        }
                    }
                    if (any_organization_checked_false===false){
                        const index_of_affiliations = state.affiliations.findIndex(affiliation => affiliation.affiliation_code===affiliation_code);
                        let affiliation = state.affiliations[index_of_affiliations]
                        affiliation.checked = checked;
                        state.affiliations.splice(index_of_affiliations, 1, affiliation);
                        const index_of_area = state.areas.findIndex(area => area.area === affiliation.area);
                        let any_affiliation_checked_false = false;
                        for (const affiliation of state.affiliations){
                            if((areas[index_of_area].affiliations.includes(affiliation.affiliation))&&(affiliation.checked===false)){
                                any_affiliation_checked_false = true;
                                break
                            }
                        }
                        if(any_affiliation_checked_false===false){
                            let area = state.areas[index_of_area];
                            area.checked = checked;
                            state.areas.splice(index_of_area, 1, area);
                        }
                    }
                } else {
                    const index_of_affiliations = state.affiliations.findIndex(affiliation => affiliation.organizations.includes(code))
                    let affiliation = state.affiliations[index_of_affiliations]
                    affiliation.checked = checked;
                    state.affiliations.splice(index_of_affiliations, 1, affiliation);
                    const index_of_areas = state.areas.findIndex(area => area.affiliations.includes(affiliation.affiliation))
                    let area = state.areas[index_of_areas]
                    area.checked = checked;
                    state.areas.splice(index_of_areas, 1, area);
                }
                state.is_ever_updated = true;
            }
        },
        /**
         * ユーザが「絞り込みクリア×」をクリックした際に、エリア・都道府県・自治体の"checked"を"false"(チェックを外す)に更新する
         * @param {Object} state 
         */
        uncheckAll (state) {
            state.areas.forEach((area, index) => {
                area.checked = false;
                state.areas.splice(index, 1, area);
            });
            state.affiliations.forEach((affiliation, index) => {
                affiliation.checked = false;
                state.affiliations.splice(index, 1, affiliation);
            });
            state.organizations.forEach((organization, index) => {
                organization.checked = false;
                state.organizations.splice(index, 1, organization);
            });
            state.is_ever_updated = false;
        },
        /**
         * 
         * @param {*} state 
         */
        resetIsEverUpdated (state) {
            state.is_ever_updated = false;
        },
    },
    getters: {
        getIsEverUpdated (state) {
            return state.is_ever_updated;
        },
        getNumberOfOrganizations (state){
            return state.organizations.length;
        },
        getAffiliationByName: (state) => (affiliation_name) => {
            const index = state.affiliations.findIndex(affiliation => affiliation.affiliation == affiliation_name);
            return state.affiliations[index];
        },
        getOrganizationNameByCode: (state) => (code) => {
            const index = state.organizations.findIndex(organization => organization.code == code);
            return state.organizations[index].name;
        },
        getAffiliationsByAffiliationCodes: (state) => (affiliation_codes) => {
            return state.affiliations.filter(function (affiliation) {
                return  affiliation_codes.includes(affiliation.affiliation_code);
            });
        },
        getOrganizationsByAffiliationCode: (state) => (affiliation_code) => {
            //console.log(state.organizations);
            return state.organizations.filter(function (organization) {
                return  organization.affiliation_code  === affiliation_code;
            });
        },
        getAreaCheckedByName: (state) => (area_name) => {
            const index = state.areas.findIndex(area => area.area == area_name);
            return state.areas[index].checked;
        },
        getAffiliationCheckedByCode: (state) => (code) => {
            const index = state.affiliations.findIndex(affiliation => affiliation.affiliation_code == code);
            return state.affiliations[index].checked;
        },
        getOrganizationCheckedByCode: (state) => (code) => {
            const index = state.organizations.findIndex(organization => organization.code == code);
            return state.organizations[index].checked;
        },
        getCheckedAreaNames (state) {
            const checked_areas = state.areas.filter(function (area) {
                return area.checked  === true;
            });
            return checked_areas.map(area => {
                return area.area;
            });
        },
        getCheckedAffiliationNames (state) {
            const checked_affiliations = state.affiliations.filter(function (affiliation) {
                return affiliation.checked  === true;
            });
            return checked_affiliations.map(affiliation => {
                return affiliation.affiliation;
            });
        },
        getCheckedOrganizationNames (state) {
            const checked_organizations = state.organizations.filter(function (organization) {
                return organization.checked  === true;
            });
            return checked_organizations.map(organization => {
                return organization.name;
            });
        },
        /**
         * フィルタリングメニューで、都道府県にチェックを入れたもののみを返す
         * @param {*} state 
         * @returns {list} - 都道府県コード（団体コード先頭2桁）のリストを返す
         */
        getCheckedAffiliationCodes (state) {
            const checked_affiliations = state.affiliations.filter(function (affiliation) {
                return  affiliation.checked  === true;
            });
            return checked_affiliations.map(affiliation => {
                return affiliation.affiliation_code;
            });
        },
        /**
         * フィルタリングメニューで、自治体にチェックを入れたもののみを返す
         * @param {*} state 
         * @returns {list} - 団体コードのリストを返す
         */
        getCheckedOrganizationCodes (state) {
            const checked_organizations = state.organizations.filter(function (organization) {
                return  organization.checked  === true;
            });
            return checked_organizations.map(organization => {
                return organization.code;
            });
        },
    },
}



import document_data_format from "@/assets/data/document_data_format.json"

export const search_filtering = {
    state: () => ({
        /** 下記はキーワード・文書・自治体の絞り込み条件を記録しておく */
        and_query: "",
        or_query: "",
        not_query: "",
        fiscal_year_start_min: "",
        fiscal_year_start_max: "",
        fiscal_year_end_min: "",
        fiscal_year_end_max: "",
        sub_category: [],
        types: [],
        population_min: "",
        population_max: "",
        financial_index_min: "",
        financial_index_max: "",
        /** 下記はエリア・自治体の絞り込み条件を記録しておく。自治体などの名前をリスト型で保持する。 */
        checked_codes: [], // 自治体の団体コードをリスト型で保持する。
        filtered_area_names: [],
        filtered_affiliation_names: [],
        filtered_organization_names: [],
        /** 検索条件に基づき、団体コードを絞り込むために参照する数値（自治体基礎データ）をストアにて保持する */
        organizations: organizations,
    }),
    mutations: {
        /**
         * @param {Object} state 
         * @param {String} and_query 
         * @param {String} or_query 
         * @param {String} not_query 
         */
        updateQueryText (state, args) {
            state.and_query = args.and_query;
            state.or_query = args.or_query;
            state.not_query = args.not_query;
        },
        /**
         * @param {Object} state
         * @param {Number} fiscal_year_start_min - ユーザが詳細検索条件で選択した文書の開始対象年度の下限値
         * @param {Number} fiscal_year_start_max - ユーザが詳細検索条件で選択した文書の開始対象年度の上限値
         * @param {Number} fiscal_year_end_min - ユーザが詳細検索条件で選択した文書の終了対象年度の下限値
         * @param {Number} fiscal_year_end_max - ユーザが詳細検索条件で選択した文書の終了対象年度の上限値
         * @param {Array} sub_category - ユーザが詳細検索条件で選択した文書のサブカテゴリをリスト型にしたもの
         * @param {Array} types - ユーザが詳細検索条件で選択した自治体の市区町村区分をリスト型にしたもの
         * @param {Number} population_min - ユーザが詳細検索条件で選択した自治体の人口の下限値
         * @param {Number} population_max - ユーザが詳細検索条件で選択した自治体の人口の上限値
         * @param {Number} financial_index_min - ユーザが詳細検索条件で選択した自治体の財政力指数の下限値
         * @param {Number} financial_index_max - ユーザが詳細検索条件で選択した自治体の財政力指数の上限値
         * @param {Array} checked_codes - ユーザがエリア・自治体選択フィルタにて、チェックされた自治体の団体コード（organization_code）のリスト
         * @param {Array} filtered_area_names - ユーザがエリア選択フィルタにて、チェックされたエリア名のリスト。エリア名（近畿地方など）をリスト型で保持するので注意
         * @param {Array} filtered_affiliation_names - ユーザが自治体選択フィルタにて、チェックされた都道府県名のリスト。都道府県名（東京都など）をリスト型で保持するので注意
         * @param {Array} filtered_organization_names - ユーザが自治体選択フィルタにて、チェックされた自治体名のリスト。自治体名（港区など）をリスト型で保持するので注意
         */
        updateFiltering (state, args) {
            if(args.fiscal_year_start_min!==""&&args.fiscal_year_start_min<=0){
                console.log(args.fiscal_year_start_min);
                throw new Error("正しい値が入力されていません。")
            }
            state.fiscal_year_start_min = args.fiscal_year_start_min;
            if(args.fiscal_year_start_max!==""&&args.fiscal_year_start_max<=0){
                console.log(args.fiscal_year_start_max);
                throw new Error("正しい値が入力されていません。")
            }
            state.fiscal_year_start_max = args.fiscal_year_start_max;
            state.fiscal_year_end_min = args.fiscal_year_end_min;
            state.fiscal_year_end_max = args.fiscal_year_end_max;
            state.sub_category = args.sub_category;
            state.types = args.types;
            state.population_min = args.population_min;
            state.population_max = args.population_max;
            state.financial_index_min = args.financial_index_min;
            state.financial_index_max = args.financial_index_max;
            state.checked_codes = args.checked_codes;
            state.filtered_area_names = args.filtered_area_names;
            state.filtered_affiliation_names = args.filtered_affiliation_names;
            state.filtered_organization_names = args.filtered_organization_names;
        },
    },
    getters: {
        getAndQuery (state) {
            return state.and_query;
        },
        getOrQuery (state) {
            return state.or_query;
        },
        getNotQuery (state) {
            return state.not_query;
        },
        getFiscalYearStartMin (state) {
            return state.fiscal_year_start_min;
        },
        getFiscalYearStartMax (state) {
            return state.fiscal_year_start_max;
        },
        getFiscalYearEndMin (state) {
            return state.fiscal_year_end_min;
        },
        getFiscalYearEndMax (state) {
            return state.fiscal_year_end_max;
        },
        getSubCategory (state) {
            return state.sub_category;
        },
        getTypes (state) {
            return state.types;
        },
        getPopulationMin (state) {
            return state.population_min;
        },
        getPopulationMax (state) {
            return state.population_max;
        },
        getFinancialIndexMin (state) {
            return state.financial_index_min;
        },
        getFinancialIndexMax (state) {
            return state.financial_index_max;
        },
        getCheckedCodes (state) {
            return state.checked_codes;
        },
        getFilteredAreaNames (state) {
            return state.filtered_area_names;
        },
        getFilteredAffiliationNames (state) {
            return state.filtered_affiliation_names;
        },
        getFilteredOrganizationNames (state) {
            return state.filtered_organization_names;
        },
        getFilteringInText (state) {
            // console.log("search_filtering_store.js", state);
            let filtering_text = [];
            if(state.and_query!==""){
                filtering_text.push(state.and_query);
            }
            if(state.or_query!==""){
                filtering_text.push(state.or_query);
            }
            if(state.not_query!==""){
                filtering_text.push(state.not_query);
            }
            if(state.fiscal_year_start_min!==""){
                filtering_text.push(state.fiscal_year_start_min);
            }
            if(state.fiscal_year_start_max!==""){
                filtering_text.push(state.fiscal_year_start_max);
            }
            if(state.fiscal_year_end_min!==""){
                filtering_text.push(state.fiscal_year_end_min);
            }
            if(state.fiscal_year_end_max!==""){
                filtering_text.push(state.fiscal_year_end_max);
            }
            if(Array.isArray(state.sub_category)&&state.sub_category.length!==0){
                let readable_sub_categories = [];
                state.sub_category.forEach(sub_category => {
                    readable_sub_categories.push(document_data_format.sub_category[sub_category]);
                });
                filtering_text.push(readable_sub_categories);
            }
            if(Array.isArray(state.types)&&state.types.length!==0){
                filtering_text.push(state.types);
            }
            if(state.population_min!==""){
                filtering_text.push(state.population_min);
            }
            if(state.population_max!==""){
                filtering_text.push(state.population_max);
            }
            if(state.financial_index_min!==""){
                filtering_text.push(state.financial_index_min);
            }
            if(state.financial_index_max!==""){
                filtering_text.push(state.financial_index_max);
            }
            if(Array.isArray(state.filtered_area_names)&&state.filtered_area_names.length>0){
                filtering_text.push(state.filtered_area_names);
            }
            if(Array.isArray(state.filtered_affiliation_names)&&state.filtered_affiliation_names.length>0){
                filtering_text.push(state.filtered_affiliation_names);
            }
            if(Array.isArray(state.filtered_organization_names)&&state.filtered_organization_names.length>0){
                filtering_text.push(state.filtered_organization_names);
            }
            return filtering_text.join("/");
        },
        /**
         * 自治体選択フィルタ、エリア・自治体選択フィルタに合致する団体コードのみに絞り込み、返す
         * @param {Object} state 
         * @param {integer} population_min - 人口下限値
         * @param {integer} population_max - 人口上限値
         * @param {Array} types - 都道府県市区町村区分の値
         * @param {Float} financial_index_min - 財政力指数下限
         * @param {Float} financial_index_max - 財政力指数上限
         * @param {Array} checked_codes - エリア・自治体選択フィルタにて、チェックされた自治体の団体コード（organization_code）のリスト
         * @returns {Array} - organization_codeのリストが返る
         */
        filterOrganizationCodes (state) {
            let matched_organizations = state.organizations;
            let matched = false;
            if (state.population_min!==""){
                matched = true;
                matched_organizations = matched_organizations.filter(function (organization) {
                    return  organization.population >= state.population_min;
                });
            }
            if (state.population_max!==""){
                matched = true;
                matched_organizations = matched_organizations.filter(function (organization) {
                    return  organization.population <= state.population_max;
                });
            }
            console.log(matched_organizations);
            if (Array.isArray(state.types)&&state.types.length!==0){
                matched = true;
                matched_organizations = matched_organizations.filter(function (organization) {
                    return state.types.includes(organization.type);
                });
            }
            if (state.financial_index_min!==""){
                matched = true;
                matched_organizations = matched_organizations.filter(function (organization) {
                    return  organization.financial_index >= state.financial_index_min;
                });
            }
            if (state.financial_index_max!==""){
                matched = true;
                matched_organizations = matched_organizations.filter(function (organization) {
                    return  organization.financial_index <= state.financial_index_max;
                });
            }
            // console.log(matched_organizations);
            if (Array.isArray(state.checked_codes)&&state.checked_codes.length!==0){
                matched = true;
                // console.log(matched_organizations);
                // console.log(state.checked_codes);
                matched_organizations = matched_organizations.filter(function (organization) {
                    return state.checked_codes.includes(organization.code);
                });
            }
            if(matched){
                //console.log(matched_organizations);
                // return matched_organizations.map(organization => {
                //     return organization.code;
                // });
                const matched_codes_list = matched_organizations.map(organization => {
                    return organization.code;
                });
                console.log(matched_codes_list);
                return matched_codes_list;
            } else {
                return []
            }
        },
    },
}